<template>
  <div class="philaser">
    <Hero src="obrve-hero.jpg" :heading-text="'PhiLASER &amp; Nanoremoval'" :cta-text="'ZAKAŽITE TERMIN'" background-url="phi-header.jpg"></Hero>
    <HeadlineCopy src1="phi-laser.jpg" src2="laser.png" :heading-text="'PHI laser'" :heading-subtitle="'Tretmani koji se mogu izvoditi PhiLaserom su uklanjanje tetovaža, trajne šminke, hiperpigmentacija i karbon piling. U našem salonu, na stručan i profesionalan način uspešno rešavamo problem neželjenih tetovaža, tretmanom Philasera za uklanjanje tetovaža. Ovaj tretman je veoma tražen jer prilikom njega nema krvarenja i nema neželjenih efekata. Za uklanjanje tetovaže potrebno je nekoliko tretmana, koji obično vrlo kratko traju. Broj tretmana zavisi od vrste boje i od dubine na kojoj se ona nalazi.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'UKLANJANJE TETOVAŽA - NEKI TEKST'" background-url="uklanjanje.jpg" background-url-mobile="prvoklasna-usluga-mobile.jpg"></SingleTextComponent>
    <HeadlineCopy src1="nanoremoval.jpg" src2="logophi.png" :heading-text="'Nanoremoval'" :heading-subtitle="'NanoRemoval je površinska tehnika i kao takva, veoma je nežna. To znači da ne prouzrokuje oštećenja kože, kao i veoma malo iritacije kože i pruža izuzetno brze rezultate. Zarastanje nakon tretmana  je veoma brzo i bez ikakvog rizika od ostajanja ožiljaka. Uspesno uklanjamo sve vrste trajne šminke – obrve, ajljaner, usne i tetovaže. Krećući se kroz slojeve kože, formira malu krastu na području koje tretiramo. Krasta će zatim početi da otpada prirodnim putem i sa sobom odneti deo pigmenta.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'PhiLaser',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>